import "lazysizes";
import {
	smoothScroll,
	debounce,
	normalizeVH,
} from "./bundle/rcobiella-helpers.js";
import { gsap } from "gsap/all";
import Scrollbar from "smooth-scrollbar";
import Swup from "swup";
import SwupFadeTheme from "@swup/fade-theme";
import SwupDebugPlugin from "@swup/debug-plugin";

import SwupBodyClassPlugin from "@swup/body-class-plugin";

import Swiper, { Navigation, Lazy, EffectFade } from "swiper";
Swiper.use([Navigation, Lazy, EffectFade]);

const TOUCH =
	"ontouchstart" in window ||
	navigator.maxTouchPoints > 0 ||
	navigator.msMaxTouchPoints > 0;

normalizeVH();

window.onresize = () => {
	normalizeVH();
};

let $smoothScrollbar;
/* -------------------------------------------------------------------------- */
/*                              LazySizes config                              */
/* -------------------------------------------------------------------------- */

window.lazySizesConfig = {
	loadMode: 2,
	expand: 1000,
};

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

window.onload = function () {
	_windowLoad();
};

function _windowLoad() {
	document.body.classList.remove("preload-transitions");
	document.getElementById("loader").classList.add("is-loaded");
	// $("#loader").addClass("is-loaded");
	_updateHeaderVars();
	initSmoothScrollbar();
}

function _updateHeaderVars() {
	let header = document.querySelector(".header-main");
	if (header) {
		let headerW = header.offsetWidth;
		let headerH = header.offsetHeight;
		document.documentElement.style.setProperty(
			"--headerWidth",
			headerW + "px"
		);
		document.documentElement.style.setProperty(
			"--headerHeight",
			headerH + "px"
		);
	}
}

/* ---------------------------------------------------------------- */
/*                               Swup                               */
/* ---------------------------------------------------------------- */

const swup = new Swup({
	plugins: [
		new SwupFadeTheme(),
		new SwupBodyClassPlugin(),
		new SwupDebugPlugin(),
	],
}); // only this line when included with script tag

document.addEventListener("swup:contentReplaced", (event) => {
	console.log("-- swup:contentReplaced", event);
	// closeAllSubmenus();
	initSmoothScrollbar();
	initSwiper();
});

// document.addEventListener("swup:willReplaceContent", (event) => {
// 	console.log("swup:willReplaceContent", event);
// 	console.log("smooth", $smoothScrollbar);
// });

document.addEventListener("swup:clickLink", (event) => {
	console.log("-- swup:clickLink", event);
	setTimeout(() => {
		closeAllSubmenus();
		smCloseSubmenus();
	}, 500);
	// closeAllSubmenus();
});

function closeAllSubmenus() {
	if (TOUCH) return;
	// const $otherViews = document.querySelector(".view:not(.view-home)");
	let activeMenus = document.querySelectorAll(".menu-header .active");
	if (activeMenus && activeMenus.length) {
		activeMenus.forEach((am) => {
			am.classList.remove("active");
		});
	}
	// jQuery(".menu-header .active").removeClass("active");

	// Hide the rest
	gsap.to(".submenu", {
		autoAlpha: 0,
		duration: 0.5,
	});

	document.body.classList.remove("is-submenu-open");

	// if ($otherViews) {
	// 	gsap.to($otherViews, {
	// 		autoAlpha: 0,
	// 		duration: 0.5,
	// 	});
	// }
}

function smCloseSubmenus() {
	if (!TOUCH) return;
	document.querySelectorAll(".menu-header-item").forEach(function (el) {
		const submenu = el.dataset.id;
		const $submenuEl = document.querySelector(".submenu." + submenu);

		if ($submenuEl) {
			el.classList.remove("is-open");
			jQuery($submenuEl).slideUp();
			document.body.classList.remove("is-sm-menu-open");
		}
	});
}

/* ---------------------------------------------------------------- */
/*                              Swiper                              */
/* ---------------------------------------------------------------- */

function initSwiper() {
	let swiperEls = document.querySelector(".swiper-container");
	if (!swiperEls) return false;

	gsap.set(".swiper-container", {
		opacity: 0,
	});
	var mySwiper = new Swiper(".swiper-container", {
		// Optional parameters
		loop: true,
		effect: "fade",
		fadeEffect: {
			crossFade: false,
		},
		// Navigation arrows
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		preloadImages: false,
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 3,
		},

		on: {
			slideChange: function (swiper) {
				let ind = +swiper.realIndex + 1;
				let _ind = ind < 10 ? `0${ind}` : ind;

				let count = document.querySelector(".count .current");
				if (count) {
					count.textContent = _ind;
				}
				// $(".count .current").text(_ind);
			},
		},
	});

	gsap.to(".swiper-container", {
		opacity: 1,
		duration: 0.5,
	});
}

/* ---------------------------------------------------------------- */
/*                         Smooth scrollbar                         */
/* ---------------------------------------------------------------- */

// Init smooth scrollbar
// only on homepage
function initSmoothScrollbar() {
	if (TOUCH) return;
	Scrollbar.destroyAll();

	let options = {
		damping: 0.1,
	};

	const $wrapper = document.querySelector(".home-scroll-wrapper");
	if (!$wrapper) return false;

	let $smoothScroll = Scrollbar.init($wrapper, options);
}

jQuery(function ($) {
	// function isMobile() {
	// 	return window.innerWidth < 768 && "ontouchstart" in window;
	// }

	/* ---------------------------------------------------------------- */
	/*                Reposition header menus for mobile                */
	/* ---------------------------------------------------------------- */

	if (window.innerWidth < 768 && "ontouchstart" in window) {
		$("body").addClass("is-mobile");
		$(".submenu.tax-4").insertAfter($('[data-id="tax-4"]'));
		$(".submenu.tax-5").insertAfter($('[data-id="tax-5"]'));
		$(".submenu.type-writings").insertAfter($('[data-id="type-writings"]'));
		$("header.header").addClass("is-mobile");

		$(".menu-header-item").on("click", function (e) {
			const dis = $(this);
			const submenu = dis.attr("data-id");
			const $submenuEl = $(".submenu." + submenu);

			if ($submenuEl.length) {
				dis[0].classList.toggle("is-open");
				if (dis[0].classList.contains("is-open")) {
					document.body.classList.add("is-sm-menu-open");
					$submenuEl.slideDown();
				} else {
					$submenuEl.slideUp();
					document.body.classList.remove("is-sm-menu-open");
				}
				// document.body.classList.toggle("is-submenu-open");
				// $submenuEl.slideToggle();
			} else {
				// Close all submenus
				smCloseSubmenus();
			}
		});

		// Close submenus when clicking view with menu open
		$("body").on("click", ".view", function (e) {
			if (document.body.classList.contains("is-sm-menu-open")) {
				smCloseSubmenus();
			}
		});
	}

	/* ---------------------------------------------------------------- */
	/*                     Reposition hover images                      */
	/* ---------------------------------------------------------------- */

	$(".index-image").each(function () {
		const dis = $(this);
		dis.appendTo(".index-images");
		let randomLeft = getRandomInt(1, 50),
			randomTop = getRandomInt(1, 50);

		dis.css("left", `${randomLeft}%`);
		dis.css("top", `${randomTop}%`);
	});

	$(".menu-header-item").on("click", function (e) {
		if (window.innerWidth < 768 && "ontouchstart" in window) {
			return false;
		}
		const dis = $(this);
		const submenu = dis.attr("data-id");
		const $submenuEl = $(".submenu." + submenu);

		if ($submenuEl.length) {
			const $others = $(".submenu").not($submenuEl);
			const $otherViews = $(".view:not(.view-home)");

			// If it's open, we close it
			if (dis.hasClass("active")) {
				$(".menu-header .active").removeClass("active");
				gsap.to($submenuEl, {
					autoAlpha: 0,
					duration: 0.5,
					onComplete: function () {},
				});

				document.body.classList.remove("is-submenu-open");
			} else {
				document.body.classList.add("is-submenu-open");
				$(".menu-header .active").removeClass("active");
				dis.addClass("active");
				// If not, we open it and close the rest

				// Hide views first
				let delay = 0;

				gsap.to($submenuEl, {
					autoAlpha: 1,
					duration: 0.5,
					delay: delay,
				});

				// Hide the rest
				gsap.to($others, {
					autoAlpha: 0,
					duration: 0.5,
				});
			}

			// Show submenu
		}
	});

	/* ---------------------------------------------------------------- */
	/*                              Header                              */
	/* ---------------------------------------------------------------- */

	function updateHeaderVars() {
		let header = $("header.header .header-main");
		if (header.length) {
			let headerW = header.get(0).offsetWidth;
			let headerH = header.get(0).offsetHeight;
			document.documentElement.style.setProperty(
				"--headerWidth",
				headerW + "px"
			);
			document.documentElement.style.setProperty(
				"--headerHeight",
				headerH + "px"
			);
		}
	}

	updateHeaderVars();

	window.addEventListener("resize", updateHeaderVars);

	// Submenu hovers
	$(".submenu-item[data-ref]").on("mouseenter mouseleave", function (e) {
		if (TOUCH) return;
		const $dis = $(this),
			ref = $dis.attr("data-ref"),
			$ref = $(".index-image." + ref),
			$view = $("#swup");

		if ($ref.length) {
			if (e.type == "mouseenter") {
				gsap.to($ref, {
					autoAlpha: 1,
					duration: 0.5,
				});

				// gsap.to($view, {
				// 	opacity: 0.75,
				// 	filter: "blur(10px)",
				// 	duration: 0.5,
				// });
			} else {
				gsap.to($ref, {
					autoAlpha: 0,
					duration: 0.5,
				});

				// gsap.to($view, {
				// 	opacity: 1,
				// 	filter: "blur(0px)",
				// 	duration: 0.5,
				// });
			}
		}
	});

	/* ---------------------------------------------------------------- */
	/*                              Swiper                              */
	/* ---------------------------------------------------------------- */

	initSwiper();

	/* -------------------------------------------------------------------------- */
	/*                                Loader + AOS                                */
	/* -------------------------------------------------------------------------- */

	let ignore_unload = false;

	// Prevent if link has mailto
	$("a[href^=mailto], a[href^=tel]").on("click", function () {
		ignore_unload = true;
	});

	$(window).on("beforeunload", function (e) {
		if (!ignore_unload) {
			$("#loader").removeClass("is-loaded");
		}
		ignore_unload = false;
	});
	// console.log("before load");

	// window.addEventListener("DOMContentLoaded", windowLoad);

	$(window).load(function () {
		// Remove preload-transitions class from body to re-enable transitions
		$("body").removeClass("preload-transitions");
		$("#loader").addClass("is-loaded");
		updateHeaderVars();
	});
});
